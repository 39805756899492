/* You can add global styles to this file, and also import other style files */
@import "~@angular/material/prebuilt-themes/indigo-pink.css";

.largerCheckbox {
  width: 20px !important;
  height: 20px !important;
}
.mt-16 {
  margin-top: 16px;
}
.mlr {
  margin-right: 10px !important;
  margin-left: 10px !important;
}
.mr {
  margin-right: 10px !important;
}
.formpad {
  padding: 10px 20px 0 20px !important;
  margin-bottom: 0 !important;
}

.span-center {
  display: flex;
  align-items: center;
  padding-left: 10px;
}
.highlight {
  color: #001eff !important;
}

.delete-menu {
  position: absolute;
  background: white;
  padding: 3px;
  color: #666;
  font-weight: bold;
  border: 1px solid #999;
  font-family: sans-serif;
  font-size: 12px;
  box-shadow: 1px 3px 3px rgba(0, 0, 0, 0.3);
  margin-top: -10px;
  margin-left: 10px;
  cursor: pointer;
}

.delete-menu:hover {
  background: #eee;
}

.empty-container {
  text-align: center;
  font-size: 18px;
}
